<template>
  <v-sheet class="h-100">
    <div class="d-block blockInfo">
      <span class="text-h5 font-weight-black d-flex align-center">
         <v-icon size="28" color="black" class="mr-1">
          mdi-contacts
        </v-icon>
        <b>TO:</b>
      </span>
      <template v-if="!loading">
        <table class="mt-1 text-left contentInformation">
          <tr>
            <th>Người mua <span class="font-italic">(Buyer)</span>:</th>
            <td>{{ displayProp('BuyerName') }}</td>
          </tr>
          <tr>
            <th>Đơn vị <span class="font-italic">(Co. name)</span>:</th>
            <td>{{ displayProp('BuyerUnitName') }}</td>
          </tr>
          <tr>
            <th>MST <span class="font-italic">(Tax Code)</span>:</th>
            <td>{{ displayProp('BuyerTaxCode') }}</td>
          </tr>
          <tr>
            <th>Địa chỉ <span class="font-italic">(Address)</span>:</th>
            <td>{{ displayProp('BuyerAddress')  }}</td>
          </tr>
          <tr>
            <th>HTTT <span class="font-italic">(Pay. method)</span>:</th>
            <td>{{findItemInList(InvoiceConfig.PaymentMethod, 'id', displayProp('PayMethodID'), 'text' )}}</td>
          </tr>
          <tr>
            <th>STK <span class="font-italic">(Account No.)</span>:</th>
            <td>{{ displayProp('BuyerBankAccount')  }}</td>
          </tr>
        </table>
      </template>
      <template v-else>
        <div class="d-flex align-center justify-center pa-5">
           <v-progress-circular 
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    
    </div>
  </v-sheet>
</template>

<script>
import InvoiceConfig from '../CreateInvoice/config'
export default {
  name: 'ReceiverInvoiceComponent',
  props: {
    receiverInfo: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
  },
  data: () => {
    return {
      InvoiceConfig
    }
  },
  methods: {
    displayProp(prop) {
      if (this.receiverInfo != null) {
        if (typeof this.receiverInfo[prop] != 'undefined') {
          return this.receiverInfo[prop];
        } else {
          return ''
        }
      } else {
        return '';
      }
    },
    findItemInList(list, propFind, valueFind, returnProp) {
      if (valueFind == '') {
        return '';
      }
      return list.find(item => item[propFind] == valueFind)[returnProp];
    },
  }
}
</script>

<style scoped lang="scss">
.blockInfo {
  border-left: 7px solid #4285f4;
  padding:5px 0px 8px 10px;
}
.contentInformation {
  font-size: 14px;
  th {
    padding-top: 8px;
    display: block-inline;
  }
  td {
    padding-top: 8px;
    padding-left: 5px;
    color: #007bff;
  }
}
</style>