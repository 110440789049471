<template>
  <div class="d-block w-100">
    <v-card elevation="0" outlined>
      <v-card-title>
        <slot name="header">
          <span class="font-weight-bold w-100 d-block">DEtail Invocie: INVOICE GUILD</span>
          <div class="d-block w-100">
            <v-row>
              <v-col xl="4" lg="4" md="5" sx="12" sm="12">
                <div class="d-flex flex-row flex-wrap align-center">
                  <v-text-field
                    v-model="invoicePartnerIDorGUID"
                    label="InvoiceGUID or PartnerInvoiceID"
                    persistent-hint
                    hint="Search invoice detail by PartnerInvoiceID or InvoiceGUID"
                    style="width: 50%"
                  ></v-text-field>

                  <v-btn small 
                    color="primary" 
                    class="ml-2"
                    @click="getDetailInvoice"
                    >Get</v-btn>
                </div>              
              </v-col>
            </v-row>
           
          </div>
        </slot>   
      </v-card-title>
      <v-card-text>
        <v-alert type="error" v-if="nullData">
          <span> <b>{{ invoicePartnerIDorGUID }}</b> - can not be found in
            <span class="font-italic text-decoration-underline" style="cursor:pointer"
              @click="openTabEhoadon"
            >BKAV system (ehoadon)</span>
          </span>
        </v-alert>
        <v-sheet>
          <!-- Invoice Information -->
          <v-row>
            <v-col xl="4" lg="4" md="5" sx="12" sm="12">
              <InvoiceNo 
                :loading="loading"
                :error="error"
                :invoiceInfo="invoiceInfo"
              />
            </v-col>
          </v-row>

           <!-- FROM - TO -->
          <v-row justify="space-between"> 
            <v-col xl="5" md="6" sx="12" sm="12">
              <SellerDetailInfo 
                ref="SellerDetailComponent" />
            </v-col>
            <v-col xl="5" md="6" sx="12" sm="12">
              <ReceiverDetailInfo 
                :loading="loading"
                :error="error"
                :receiverInfo="invoiceInfo"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col xl="9" md="10" sx="12" sm="12">
              <HistoryInvoice 
                :invoiceGUID="invoicePartnerIDorGUID"
                ref="HistoryInvoiceComponent"
              />
            </v-col>
          </v-row>

          <!-- List services -->
          <v-row>
            <v-col cols="12">
              <ListServiceItem 
               :listItemService="listItemService"
               :loading="loading"
               :error="error"
              />
            </v-col> 
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SellerDetailInfo from './SellerDetailInfo'
import ReceiverDetailInfo from './ReceiverDetailInfo'
import ListServiceItem from './ListServiceItem'
import InvoiceService from '@/services/invoice'
import InvoiceNo from './InvoiceNo'
import HistoryInvoice from './HistoryInvoice'
export default {
  name: "ViewDetailInvoiceComponent",
  components: {
    SellerDetailInfo,
    ReceiverDetailInfo,
    ListServiceItem,
    InvoiceNo,
    HistoryInvoice
  },  
  data: () => {
    return {
      invoicePartnerIDorGUID: '',
      listItemService: [],
      invoiceInfo: null,
      loading: false,
      error: false,
      nullData: false,
    }
  },
  mounted() {
    if (this.$route.params.invoiceGUID) {
      this.invoicePartnerIDorGUID = this.$route.params.invoiceGUID;
      this.$refs.HistoryInvoiceComponent.setInvoiceGUID(this.invoicePartnerIDorGUID)
      this.getDetailInvoice();
    }
  },
  methods: {
    async getDetailInvoice() {
      this.loading = true;
      this.$refs.HistoryInvoiceComponent.getHistoryInvoice();
      this.$refs.SellerDetailComponent.searchBusinessInfo();
      let detailRes = await InvoiceService.getDetailInvoice(this.invoicePartnerIDorGUID);
      if (detailRes.error) {
        this.loading = false;
        this.error = true;
        return;
      }
      this.loading = false;
      this.error = false;

      // parse data  
      if (detailRes?.object != null) {
        this.listItemService = detailRes?.object?.ListInvoiceDetailsWS;
        this.invoiceInfo = detailRes?.object?.Invoice;
        this.nullData = false;
      } else {
        this.nullData = true;
      }
    },
    openTabEhoadon() {
      window.open('https://van.ehoadon.vn/')
    }  
  }
}
</script>

<style>

</style>