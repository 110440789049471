const InvoiceType = [
  {
    id: 1,
    text: 'Hóa đơn Giá trị gia tăng'
  },
  {
    id: 2, 
    text: 'Hóa đơn bán hàng'
  },
  {
    id: 3, 
    text: 'Chưa sử dụng'
  },
  {
    id: 4,
    text: 'Hóa đơn bán hàng (dành cho Tổ chức, Cá nhân trong khu PTQ)'
  },
  {
    id: 5,
    text: 'Phiếu xuất kho & vận chuyển nội bộ'
  },
  {
    id: 6,
    text: 'Phiếu xuất kho gửi bán hàng đại lý'
  }
];

const PaymentMethod = [
  {
    id: 1,
    text: 'Tiền mặt',
    name: 'TM'
  },
  {
    id: 2,
    text: 'Chuyển khoản',
    name: 'CK'
  },
  {
    id: 3,
    text: 'Tiền mặt/Chuyển khoản',
    name: 'TM/CK'
  },
  {
    id: 4,
    text: 'Xuất hàng cho chi nhánh',
    name: 'Xuất hàng cho chi nhánh'
  },{
    id: 5,
    text: 'Hàng biếu tặng',
    name: 'Hàng biếu tặng'
  },
  {
    id: 6,
    text: 'Cấn trừ công nợ',
    name: 'Cấn trừ công nợ'
  },
  {
    id: 7,
    text: 'Trả hàng',
    name: 'TM'
  },
  {
    id: 8,
    text: 'Khuyến mại không thu tiền',
    name: 'Khuyến mại, quảng cáo, hàng mẫu không thu tiền'
  },
  {
    id: 9,
    text: 'Xuất sử dụng',
    name: 'Xuất sử dụng'
  },
  {
    id: 10,
    text: 'Không thu tiền',
    name: 'Không thu tiền'
  },
  {
    id: 11,
    text: 'Nhờ thu chấp nhận chứng từ',
    name: 'D/A'
  },
  {
    id: 12,
    text: 'Nhờ thu đổi chứng từ',
    name: 'D/P'
  },
  {
    id: 13,
    text: 'Trả trước',
    name: 'TT'
  },
  {
    id: 14,
    text: 'Thư tín dụng',
    name: 'L/C'
  },
  {
    id: 15,
    text: 'Công nợ',
    name: 'Công nợ'
  },
  {
    id: 16,
    text: 'Nhờ thu',
    name: 'Nhờ thu'
  },
  {
    id: 17,
    text: 'TM/CK/B',
    name: 'TM/CK/B'
  },
];

const ReceiveTypeID = [
  {
    id: 1,
    text: 'Email'
  },
  {
    id: 2,
    text: 'Tin nhắn'
  },
  {
    id: 3,
    text: 'Email và tin nhắn'
  },
  {
    id: 4,
    text: 'Chuyển phát nhanh'
  }
];

const ListCurrency = [
  {
    id: 'VND',
    text: 'Việt Nam đồng'
  },
  {
    id: 'USD',
    text: 'Đô la Mỹ'
  },
  {
    id: 'EUR',
    text: 'Đồng Euro'
  },
  {
    id: 'GBP',
    text: 'Bảng Anh'
  },
  {
    id: 'CNY',
    text: 'Nhân dân tệ'
  },
  {
    id: 'CHF',
    text: 'Phơ răng Thuỵ Sĩ'
  }
];

const ListTaxRate = [
  {
    id: 1,
    text: '0%',
    value: 0/100
  },
  {
    id: 2,
    text: '5%',
    value: 5/100
  },
  {
    id: 3,
    text: '10%',
    value: 10/100
  },
  {
    id: 4,
    text: 'Không chịu thuế'
  },
  {
    id: 5,
    text: 'Không kê khai thuế'
  }
];

const ListIsDiscount = [
  {
    id: 0,
    text: 'Không'
  },
  {
    id: 1,
    text: 'Chiết khấu'
  }
];

const ListItemType = [
  {
    id: 0,
    text: 'Hàng hoá dịch vụ (mặc định)'
  },
  {
    id: 1,
    text: 'Thuế khác'
  },
  {
    id: 2,
    text: 'Phí khác'
  },
  {
    id: 3,
    text: 'Phí phục vụ'
  },
  {
    id: 4,
    text: 'Ghi chú'
  },
  {
    id: 10,
    text: 'Tiền đất giảm trừ khi tính thuế'
  }
];

const ListStatusInvoice = [
  {
    id: 1,
    text: 'Mới tạo',
    color: ''
  },
  {
    id: 2,
    text: 'Đã phát hành',
    color: '#1967b5'
  },
  {
    id: 3,
    text: 'Đã hủy',
    color: '#ff004f'
  },
  {
    id: 5, 
    text: 'Chờ thay thế',
    color: ''
  },
  {
    id: 6,
    text: 'Thay thế',
    color: ''
  },
  {
    id: 7, 
    text: 'Chờ điều chỉnh',
    color: ''
  },
  {
    id: 8, 
    text: 'Điều chỉnh',
    color: ''
  },
  {
    id: 9, 
    text: 'Bị thay thế', 
    color: ''
  },
  {
    id: 10, 
    text: 'Bị điều chỉnh',
    color: ''
  },
  {
    id: 11, 
    text: 'Trống (Đã cấp số, Chờ ký)',
    color: '#aa6d64'
  },
  {
    id: 12, 
    text: 'Không sử dụng',
    color: ''
  },
  {
    id: 13, 
    text: 'Chờ hủy',
    color: ''
  },
  {
    id: 14,
    text: 'Chờ điều chỉnh chiết khấu',
    color: ''
  },
  {
    id: 15, 
    text: 'Điều chỉnh chiết khấu',
    color: ''
  }
]

export default { 
  InvoiceType,
  PaymentMethod,
  ReceiveTypeID,
  ListCurrency,
  ListTaxRate,
  ListIsDiscount,
  ListItemType,
  ListStatusInvoice
}