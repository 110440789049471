<template>
  <div class="d-block">
    <span class="d-flex w-100 align-center mb-2 text-h5 font-weight-bold">
      <v-icon size="28" color="black" class="mr-1">
        mdi-history
      </v-icon>
      <b>Invoice processing history</b>
    </span>
    <v-simple-table class="table-list-history">
      <template v-slot:default>
        <tr class="header">
          <th v-for="itemTitle in listTitle" :key="itemTitle">
            <div class="d-flex flex-column">
              <span class="text-title-1 font-weight-bold">{{ itemTitle }}</span>
            </div>
          </th>
        </tr>
        <tbody>
          <template v-if="loading">
            <tr>
              <td :colspan="listTitle.length">
                <div class="d-flex justify-center pa-5 mt-2 align-center">
                  <v-progress-circular 
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>  
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-if="error">
              <tr>
                <td :colspan="listTitle.length">
                  <span class="red--text font-weight-bold">{{ error_message }}</span>
                </td>
              </tr>   
            </template>
            <template v-else>
              <template v-if="listHistory.length > 0">
                <tr v-for="(itemHistory, indexHis) in listHistory" :key="indexHis">
                  <td>{{ itemHistory.STT }}</td>
                  <td>{{ moment(itemHistory.CreateDate).format('DD/MM/YYYY hh:mm:ss A') }}</td>
                  <td>{{ itemHistory.UserName }}</td>
                  <td>
                    <span v-html="itemHistory.LogContent"></span>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td :colspan="listTitle.length">
                    <span class="font-weight-bold">Empty history!</span>
                  </td>
                </tr>  
              </template>
            </template>
          
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import moment from "moment";
import InvoiceService from '@/services/invoice'
export default {
  name:"InvoiceProcessingHistoryComponent",
  props: {
    invoiceGUID: {
      type: String,
      default: '',
    }
  },
  watch: {
    invoiceGUID() {
      this.invoicePKAV = this.invoiceGUID;
    }
  },
  data: () => {
    return {
      loading: false,
      error: false,
      error_message: '',
      listTitle: ["STT", "Thời gian", "Người sử dụng", "Nội dung"],
      listHistory: [],
      invoicePKAV: ''
    }
  },
  methods: {
    moment,
    async getHistoryInvoice() {
      this.loading = true;
      let resultHistory = await InvoiceService.getProcessingHistoryInvoice(this.invoicePKAV);
      if (resultHistory.error) {
        this.loading = false;
        this.error = true;
        this.error_message = "Something went wrong!";
        return;
      }

      this.loading = false;
      this.error = false;
      this.listHistory = resultHistory.object;
    },

    setInvoiceGUID(invoiceGUID) {
      this.invoicePKAV = invoiceGUID;
    }
  }
}
</script>

<style scoped lang="scss">
.table-list-history {
  .header {
    th {
      border: 1px solid #d8d5d5;
      padding: 8px;
      font-size: 16px;
    }
  }
  tbody {
    td {
      border: 1px solid #d8d5d5;
      font-size: 16px;
    }
  }
  .tr-p-5 {
    th {
      padding: 5px;
    }
  }
}
</style>