<template>
  <div class="d-block">
    <span class="d-flex w-100 align-center mb-2 text-h5 font-weight-bold">
      <v-icon size="28" color="black" class="mr-1">
        mdi-file-document
      </v-icon>
      <b>Danh sách dịch vụ</b>
    </span>
    <v-simple-table class="table-list-service">
      <template v-slot:default>
        <tr class="header">
          <th v-for="(itemTitle, indexTitle) in listTitle" :key="indexTitle">
            <div class="d-flex flex-column">
              <span class="text-title-1 font-weight-bold">{{ itemTitle.title }}</span>
              <span class="text-body-1 font-italic">({{ itemTitle.eng }})</span>
            </div>
          </th>
        </tr>
        <tr class="font-weight-bold primary-text header">
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
          <th>6 = 4 x 5</th>
          <th>7</th>
          <th>8 = 6 x 7</th>
        </tr>

        <tbody>
          <template v-if="loading">
            <tr>
              <td :colspan="listTitle.length">
                <div class="d-flex align-center justify-center pa-5">
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-if="listItemService.length">
              <tr v-for="(itemService, index) in listItemService" :key="index">
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-left">{{ itemService.ItemName }}</td>
                <td class="text-center">{{ itemService.UnitName }}</td>
                <td class="text-center">{{ itemService.Qty }}</td>
                <td class="text-right">{{ currencyFormat(itemService.Price, 'end', '') }}</td>
                <td class="text-right">{{ currencyFormat(itemService.Amount, 'end', '') }}</td>
                <td class="text-center">{{ findItemInList(InvoiceConfig.ListTaxRate, 'id', itemService.TaxRateID, 'text') }}</td>
                <td class="text-right">{{ currencyFormat(itemService.TaxAmount, 'end', '') }}</td>
              </tr>
              <!-- Spacing -->
              <template v-if="listItemService.length > 0 && listItemService.length < 5">
                <tr v-for="row in 4" :key="row">
                  <td v-for="col in listTitle.length" :key="col"></td>
                </tr>
              </template>
              <!-- Summary price - amount - tax -->
              <tr>
                <td :colspan="listTitle.length - 2" class="text-right">
                  Cộng tiền hàng <span class="font-italic">(Sub total)</span>
                </td>
                <td colspan="2" class="text-right font-weight-bold">{{ summaryPrice('Price') }}</td>
              </tr>
              <tr>
                <td :colspan="listTitle.length - 2" class="text-right">
                  Cộng tiền thuế GTGT <span class="font-italic">(VAT amount)</span>
                </td>
                <td colspan="2" class="text-right font-weight-bold">{{ summaryPrice('TaxAmount') }}</td>
              </tr>
              <tr>
                <td :colspan="listTitle.length - 2" class="text-right">
                  Tỗng cộng tiền thanh toán <span class="font-italic">(Total payment)</span>
                </td>
                <td colspan="2" class="text-right font-weight-bold">{{ summaryPrice('Amount') }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="listTitle.length">
                  <span class="text-body-1">Danh sách service rỗng</span>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>

// "ItemName": "Dịch vụ khám mắt",
//         "UnitName": "lần",
//         "Qty": 1,
//         "Price": 280000,
//         "Amount": 280000,
//         "TaxRateID": 1,
//         "TaxRate": 0,
//         "TaxAmount": 0,
//         "DiscountRate": 0,
//         "DiscountAmount": 0,
//         "SVChargeAmount": 0,
//         "IsDiscount": false,
//         "UserDefineDetails": "",
//         "IsIncrease": null,
//         "ItemCode": "",
//         "ItemTypeID": 3
import {currencyFormat} from '@/plugins/helper'
import InvoiceConfig from '../CreateInvoice/config'
export default {
  name: 'ListItemServiceComponent',
  props: {
    listItemService: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
  },
  data: () => {
    return {
      InvoiceConfig,
      listTitle: [
        {
          title: 'STT',
          eng: 'No.'
        },
        {
          title: 'Tên hàng hóa, dịch vụ',
          eng: 'Description'
        },
        {
          title: 'ĐVT',
          eng: 'Unit'
        },
        {
          title: 'SL',
          eng: 'Quantity'
        },
        {
          title: 'Đơn giá',
          eng: 'Unit Price'
        },
        {
          title: 'Thành tiền',
          eng: 'Amount'
        },
        {
          title: 'Thuế suất',
          eng: 'Tax Rate'
        },
        {
          title: 'Tiền thuế',
          eng: 'Tax Amount'
        },
      ]
    }
  },

  methods: {
    currencyFormat,
    findItemInList(list, propFind, valueFind, returnProp) {
      return list.find(item => item[propFind] == valueFind)[returnProp];
    },
    summaryPrice(prop) {
      if (this.listItemService.length) {
          let amount = 0;
          this.listItemService.forEach((item) => {
            amount = amount + item[prop]
          })
          return this.currencyFormat(amount, 'end', '');
      } else {
        return 0;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-list-service {
  .header {
    th {
      border: 1px solid #d8d5d5;
      padding: 8px;
      font-size: 15px;
    }
  }
  tbody {
    td {
      border: 1px solid #d8d5d5;
      font-size: 15px;
    }
  }
  .tr-p-5 {
    th {
      padding: 5px;
    }
  }
}
</style>