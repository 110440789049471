<template>
  <div class="d-block">
    <span class="text-h5 font-weight-bold">
      <v-icon size="28" color="black" class="mr-1">
        mdi-barcode
      </v-icon>
      <b>Invoice Info</b>
    </span>
    <template v-if="loading">
      <div class="d-flex justify-center align-center pa-5 mt-3">
        <v-progress-circular 
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <table class="contentInformation">
        <tr>
          <th>Mẫu số <span class="font-italic">(Form No.)</span> </th>
          <td class="font-weight-bold">
            {{ displayProp('InvoiceForm') }}
          </td>
        </tr>
        <tr>
          <th>Ký hiệu <span class="font-italic">(Serial No.)</span>: </th>
          <td class="font-weight-bold">
            {{ displayProp('InvoiceSerial') }}
          </td>
        </tr>
        <tr>
          <th>Mẫu số <span class="font-italic">(Invoice No.)</span>: </th>
          <td class="font-weight-bold">
            {{ displayProp('InvoiceNo') }}
          </td>
        </tr>
      </table>
    </template>
    
  </div>
</template>

<script>
export default {
  name: "InvoiceNoComponent",
  props: {
    invoiceInfo: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    displayProp(prop) {
      if (this.invoiceInfo != null) {
        if (typeof this.invoiceInfo[prop] != 'undefined') {
          return this.invoiceInfo[prop];
        } else {
          return ''
        }
      } else {
        return '';
      }
    },
  }
}
</script>

<style scoped lang="scss">
.contentInformation {
  font-size: 14px;
  th {
    padding-top: 8px;
    display: block-inline;
    text-align: left;
  }
  td {
    padding-top: 8px;
    padding-left: 5px;
    color: #007bff;
  }
}
</style>