<template>
  <v-sheet style="height: 100%;">
    <div class="d-block blockInfo">
      <span class="text-h5 font-weight-black d-flex w-100 align-center">
        <v-icon size="28" color="black" class="mr-1">
          mdi-hospital-building
        </v-icon>
        <b>FROM</b>
      </span>
      <div v-bind:class="{
        'd-flex align-center justify-center w-100 h-90': data.loading,
        'd-block w-100': !data.loading
      }">
        <table class="mt-1 text-left contentInformation" v-if="!data.loading">
          <tr>
            <th>Đơn vị bán <span class="font-italic">(Seller)</span>:</th>
            <td>{{ data.businessInfo.tenChinhThuc }}</td>
          </tr>
          <tr>
            <th>MST <span class="font-italic">(Tax Code)</span>:</th>
            <td>{{ data.businessInfo.MST }}</td>
          </tr>
          <tr>
            <th>Địa chỉ <span class="font-italic">(Address)</span>:</th>
            <td>{{ data.businessInfo.diaChiGiaoDichChinh }}</td>
          </tr>
          <tr>
            <th>Điện thoại <span class="font-italic">(Tel)</span>:</th>
            <td>{{ data.businessInfo.phone }}</td>
          </tr>
          <tr>
            <th>STK <span class="font-italic">(Seller)</span>:</th>
            <td>{{ data.businessInfo.accountNo }}</td>
          </tr>
        </table>

        <div class="d-flex justify-center"  style="height: 50%; width: auto" v-if="data.loading">
          <v-progress-circular 
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </div> 
    </div>
  </v-sheet>
</template>

<script>
import InvoiceService from '@/services/invoice'
export default {
  name: "SellerComponent",
  data: () => { 
    return {
      searchByTaxCode: '0302593596', // default for CAOTHANG
      phone: '0908 318 389',
      accountNo: '1031100184008 - Ngân hàng MB - CN Sài Gòn - TP Hồ Chí Minh',
      data: {
        businessInfo: {
          MST: "",
          tenChinhThuc: "",
          diaChiGiaoDichChinh: "",
          diaChiGiaoDichPhu: "",
          tTrangThaiHoatDong: "",
          soDienThoai: "",
          chuDoanhNghiep: "",
          lastUpdate: "",
          phone: "",
          accountNo: "",
        },
        error: false,
        loading: false,
        error_messages :''
      }
    }
  },
  mounted() {
    //this.searchBusinessInfo();
  },
  methods: {

    async searchBusinessInfo() {
      this.data.loading = true;
      var result = await InvoiceService.getBusinessInfo(this.searchByTaxCode);
      if (result.error) {
        this.data = {
          ...this.data,
          loading: false,
          error: true,
          error_messages: 'Can not load data! Something went wrong!'
        }
        return;
      }

      this.data = {
        businessInfo: result.isError ? {
          MaSoThue: "",
          TenChinhThuc: "",
          DiaChiGiaoDichChinh: "",
          DiaChiGiaoDichPhu: "",
          TrangThaiHoatDong: "",
          SoDienThoai: "",
          chuDoanhNghiep: "",
          LastUpdate: ""
        } : result.object,
        error: result.isError ? true : false,
        error_messages: result.isError ? result.data : '',
        loading: false,
      }    

      this.data.businessInfo.phone = "0908 318 389"
      this.data.businessInfo.accountNo = "1031100184008 - Ngân hàng MB - CN Sài Gòn - TP Hồ Chí Minh"
      this.data.businessInfo.MST = "0302593596";
    },
  }
}
</script>

<style scoped lang="scss">
.contentInformation {
  font-size: 14px;
  th {
    padding-top: 8px;
    display: block-inline;
  }
  td {
    padding-top: 8px;
    padding-left: 5px;
    color: #007bff;
  }
}
.blockInfo {
  border-left: 7px solid #4285f4;
  padding:5px 0px 8px 10px;
  height: 100%;
}
.h-90 {
  height: 90%;
}
</style>