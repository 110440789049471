import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import AppConstant from '../plugins/constant'

var httpClient = new HttpClient(ApiGateway.Pas);

const prefix = '/Invoice';

export default {
  getPaymentAndLastVisit(patientID, page = 1, limit = 3) {
    var headers = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit
    };
    return httpClient.get(`${prefix}/GetListVisitAndFinalPrice/${patientID}`, {} , headers)
  },

  getBusinessInfo:(taxCode) => httpClient.get(`${prefix}/EhisBKAV/BusinessInfo/${taxCode}`),

  createANewInvoice:(invoiceData, paymentSummaryID) => httpClient.post(`${prefix}/EhisBKAV/CreateANewInvoice/${paymentSummaryID}`, {}, invoiceData),

  createBatchInvoice: (invoiceData, dateInvoice) => httpClient.post(`${prefix}/EhisBKAV/CreateBatchInvoice`, {dateTime: dateInvoice}, invoiceData),

  getListInvoiceByFormAndSerial: (commandObject) => httpClient.post(`${prefix}/EhisBKAV/GetListInvoiceByFormAndSerial`, {}, commandObject),

  getDetailInvoice: (invoiceGUID) => httpClient.get(`${prefix}/EhisBKAV/GetDetailInvoice/${invoiceGUID}`, {}),

  getProcessingHistoryInvoice: (invoiceGUID) => httpClient.get(`${prefix}/EhisBKAV/GetHistoryInvoice/${invoiceGUID}`, {}),

  getMaxInvoiceNo: () => httpClient.get(`${prefix}/EhisBKAV/GetMaxInvoiceNo`),

  getShortDetailPaymentSummary: (paymentSummaryID) => httpClient.get(`${prefix}/EhisBKAV/GetShortPaymentSummaryInfo/${paymentSummaryID}`)
}